/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOkFromDiscriminatorValue, serializeAddPriceTierRequest, serializeDeletePriceTierRequest, serializeOk, serializeUpdatePriceTierRequest, type AddPriceTierRequest, type DeletePriceTierRequest, type ErrorResponse, type InternalErrorResponse, type Ok, type UpdatePriceTierRequest } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/price-tiers
 */
export interface PriceTiersRequestBuilder extends BaseRequestBuilder<PriceTiersRequestBuilder> {
    /**
     * Delete Price Tier
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     */
     delete(body: DeletePriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Add a new Price Tier for an organization.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: AddPriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Update Price Tier
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     */
     put(body: UpdatePriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Delete Price Tier
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(body: DeletePriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Add a new Price Tier for an organization.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: AddPriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Update Price Tier
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: UpdatePriceTierRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const PriceTiersRequestBuilderUriTemplate = "{+baseurl}/api/organizations/price-tiers";
/**
 * Metadata for all the requests in the request builder.
 */
export const PriceTiersRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: PriceTiersRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeDeletePriceTierRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
    post: {
        uriTemplate: PriceTiersRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeAddPriceTierRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
    put: {
        uriTemplate: PriceTiersRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeUpdatePriceTierRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
