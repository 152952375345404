/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOrderDetailsResponseFromDiscriminatorValue, createProblemDetailsFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type OrderDetailsResponse, type ProblemDetails } from '../../../models/';
// @ts-ignore
import { CustomerRequestBuilderRequestsMetadata, type CustomerRequestBuilder } from './customer/';
// @ts-ignore
import { ShippingRequestBuilderRequestsMetadata, type ShippingRequestBuilder } from './shipping/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/orders/{id}
 */
export interface OrdersItemRequestBuilder extends BaseRequestBuilder<OrdersItemRequestBuilder> {
    /**
     * The customer property
     */
    get customer(): CustomerRequestBuilder;
    /**
     * The shipping property
     */
    get shipping(): ShippingRequestBuilder;
    /**
     * Get an order doing a lookup using the Id
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<OrderDetailsResponse>}
     * @throws {ProblemDetails} error when the service returns a 400 status code
     * @throws {ErrorResponse} error when the service returns a 404 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<OrderDetailsResponse | undefined>;
    /**
     * Get an order doing a lookup using the Id
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const OrdersItemRequestBuilderUriTemplate = "{+baseurl}/api/orders/{id}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const OrdersItemRequestBuilderNavigationMetadata: Record<Exclude<keyof OrdersItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    customer: {
        requestsMetadata: CustomerRequestBuilderRequestsMetadata,
    },
    shipping: {
        requestsMetadata: ShippingRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const OrdersItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: OrdersItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            404: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOrderDetailsResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
