/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOrderDetailsResponseFromDiscriminatorValue, serializeOrderDetailsResponse, serializeUpdateCustomerRequest, type ErrorResponse, type InternalErrorResponse, type OrderDetailsResponse, type UpdateCustomerRequest } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/orders/{id}/customer
 */
export interface CustomerRequestBuilder extends BaseRequestBuilder<CustomerRequestBuilder> {
    /**
     * Update an order's customer
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<OrderDetailsResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {ErrorResponse} error when the service returns a 404 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: UpdateCustomerRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<OrderDetailsResponse | undefined>;
    /**
     * Update an order's customer
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: UpdateCustomerRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CustomerRequestBuilderUriTemplate = "{+baseurl}/api/orders/{id}/customer";
/**
 * Metadata for all the requests in the request builder.
 */
export const CustomerRequestBuilderRequestsMetadata: RequestsMetadata = {
    put: {
        uriTemplate: CustomerRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            404: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOrderDetailsResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeUpdateCustomerRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
